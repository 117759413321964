<template>
  <section>
    <section class="info-container">
      <section class="info-item-wrap">
        <p class="info-title">人员信息</p>
        <a-row
          class="m-t-18"
          type="flex"
          style="border: 1px solid #629ff0; padding: 20px 20px 0 20px;border-radius: 8px;text-align: center;"
        >
          <a-col style="width: 100%;">
            <a-row>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">单位名称</a-col>
                  <a-col class="val">{{detailInfo.companyName}}</a-col>
                </a-row>
              </a-col>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">单位类型</a-col>
                  <a-col class="val">{{detailInfo.companyTypeName}}</a-col>
                </a-row>
              </a-col>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">人员类型</a-col>
                  <a-col class="val">{{detailInfo.personnelType}}</a-col>
                </a-row>
              </a-col>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">职位工种</a-col>
                  <a-col class="val">{{detailInfo.positionWorkName}}</a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">人员姓名</a-col>
                  <a-col class="val">{{detailInfo.personnelName}}</a-col>
                </a-row>
              </a-col>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">登记时间</a-col>
                  <a-col class="val">{{detailInfo.inDate}}</a-col>
                </a-row>
              </a-col>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">联系方式</a-col>
                  <a-col class="val">{{detailInfo.phoneNumber}}</a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
      <section class="info-item-wrap">
        <p class="info-title">拉黑说明</p>
        <a-row class="p-t-14">
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key"> 有效期限 </a-col>
              <a-col class="val"> {{detailInfo.validityDate}} </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row class="p-t-14">
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key"> 布控说明 </a-col>
              <a-col class="val"> {{detailInfo.controlDesc}} </a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
      <section class="info-item-wrap">
        <p class="info-title">历史记录</p>
        <a-row class="p-t-14"
        v-for="(item, key) in detailInfo.historyList"
          :key="key"
          >
          <a-col :span="14" v-if="item.flag==0">
            <a-row class="info-item">
              <a-col class="key" style="font-size:28px;text-align: center;"> ● </a-col>
              <a-col class="val textVal">{{item.data}} {{item.content}} </a-col>
              <a-col class="val textVal"> 拉黑说明：{{item.desc}} </a-col>
            </a-row>
          </a-col>
          <a-col :span="14" v-else>
            <a-row class="info-item">
              <a-col class="key" style="font-size:28px;text-align: center;"> ● </a-col>
              <a-col class="val textVal">人员有效期结束，解除黑名单 </a-col>
              <a-col class="val textVal"> </a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
    </section>
  </section>
</template>

<script>
const columns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "姓名",
    dataIndex: "personnelName",
    align: "center",
    key: "personnelName",
  },
  { title: "职务", dataIndex: "jobName", align: "center", key: "jobName" },
  {
    title: "设备类型",
    dataIndex: "deviceType",
    align: "center",
    key: "deviceType",
    scopedSlots: { customRender: "deviceType" },
  },
  {
    title: "设备名称",
    dataIndex: "deviceName",
    align: "center",
    key: "deviceName",
  },
  {
    title: "考勤日期",
    dataIndex: "attendanceDate",
    align: "center",
    key: "attendanceDate",
  },
  {
    title: "上班签到时间",
    dataIndex: "signInTime",
    align: "center",
    key: "signInTime",
  },
  {
    title: "签到状态",
    dataIndex: "signInState",
    align: "center",
    key: "signInState",
    scopedSlots: { customRender: "signInState" },
  },
  {
    title: "签到照片",
    dataIndex: "signInPhoto",
    align: "center",
    key: "signInPhoto",
    scopedSlots: { customRender: "signInPhoto" },
  },
  {
    title: "下班签到时间",
    dataIndex: "signOutTime",
    align: "center",
    key: "signOutTime",
  },
  {
    title: "签到状态",
    dataIndex: "signOutState",
    align: "center",
    key: "signOutState",
    scopedSlots: { customRender: "signOutState" },
  },
  {
    title: "签到照片",
    dataIndex: "signOutPhoto",
    align: "center",
    key: "signOutPhoto",
    scopedSlots: { customRender: "signOutPhoto" },
  },
];
// import previewImage from '@/components/previewImage'
import moment from "moment";
export default {
  data() {
    return {
      columns,
      detailInfo: {},
      // listMap: [],
    };
  },
  // components:{previewImage},
  computed: {
    did() {
      return this.$route.query.id;
    },
  },
  created() {
    this.queryBlackDetails()
  },
  methods: {
    queryBlackDetails() {
      this.$api.queryBlackDetails(this.did).then((res) => {
        if (res.code === 200) {
          this.detailInfo = (res.data) || {};
          // let pageList = (res.data && res.data.pageList) || {};
          // this.listMap = pageList.records || [];
          // this.listMap.forEach((item, ind) => {
          //   item.key = ind + 1;
          // });
          this.detailInfo.personnelType = this.detailInfo.personnelType == 1?'管理人员':'劳务人员';
          this.detailInfo.createTime = moment(this.detailInfo.createTime).format("YYYY-MM-DD");
          if(this.detailInfo.validityDate==-1){
            this.detailInfo.validityDate = '永久';
          }else{
            this.detailInfo.validityDate = this.detailInfo.createTime + "~" + this.detailInfo.validityDate;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.info-item .val{
    text-align: start;
}
.textVal{
    height: 44px;
    line-height: 44px;
}
</style>